import React, {useRef,useEffect, useState} from 'react';
import Img from 'gatsby-image'
import Glide from '@glidejs/glide'


const autoHeight = () => {
    const slideHeight = document.querySelector('.glide__slide--active').offsetHeight;
    const glideTrack =  document.querySelector('.glide__track').offsetHeight;
    if (slideHeight != glideTrack) {
        document.querySelector('.glide__track').style.transition = 'height 0.2s ease-in-out'
        document.querySelector('.glide__track').style.height = slideHeight + 'px';
    }
}


const WebSlider = ({images}) => {
    const  slider = useRef(null)

    const  [sliderStarted, setSliderStarted] = useState(false)
    const  [imageSlider, setImageSlider] = useState(null)

    useEffect(() => {
        if (slider.current && !sliderStarted) {
            const glide = new Glide(slider.current,  { 
            startAt: 0,
            autoplay: false,
            perView: 1,
            animationTimingFunc: 'ease-in-out',
            })
            glide.on('build.after', autoHeight);
            glide.on('run.after', autoHeight);   
            glide.mount()
            setImageSlider(glide)
            setSliderStarted(true)
        }
        return () => {
            if (imageSlider) imageSlider.destroy()
        }
    }, [sliderStarted])

    if (imageSlider && images.length <= 1) imageSlider.disable()

    let handleArrowClick = (e) => {
        if(imageSlider) imageSlider.go(e.currentTarget.dataset.glideDir)
    }

    return (
        <div className="relative">
            <div onClick={(e) => handleArrowClick(e)} className="-left-10 md:-left-16 absolute inset-y-1/2 cursor-pointer duration-300 hover:opacity-50 py-3 px-1" data-glide-dir="<">
                <svg className="px-1 md:px-0" id="large-left-arrow" width="26.162" height="44.902" viewBox="0 0 26.162 44.902">
                    <g id="Group_880" data-name="Group 880" transform="translate(0)">
                        <path id="Path_388" data-name="Path 388" d="M67.6,151.888l0,0,18.921-18.83a3.621,3.621,0,0,1,5.109,5.133L73.872,154.672l17.762,16.042a3.621,3.621,0,0,1-5.109,5.133L67.6,157.018l0,0A3.624,3.624,0,0,1,67.6,151.888Z" transform="translate(-66.539 -132)" fill="#3F2E7F"/>
                    </g>
                </svg>

            </div>
            <div className="home-hero__slider--full overflow-y-scroll bg-white shadow-2xl w-full bg-white-default">
                <div ref={slider} className={`glide w-full`}>
                    <div data-glide-el="track" className="glide__track">
                        <ul className="glide__slides flex flex-wrap items-start">
                            {images && images.map((image, i) => <li key={i} className="glide__slide mb-0">
                                {<Img alt="A website portfolio item" className="mb-0" fluid={image.localFile.childImageSharp.fluid} />}
                            </li>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
            <div  onClick={(e) => handleArrowClick(e)} className="-right-10 md:-right-16 absolute inset-y-1/2 cursor-pointer duration-300 hover:opacity-50 py-3 px-1" data-glide-dir=">">
                <svg className="px-1 md:px-0" width="26.162" height="44.902" viewBox="0 0 26.162 44.902">
                    <g id="large-right-arrow" transform="translate(-53.539 -21)">
                        <g id="Group_880" data-name="Group 880" transform="translate(-13 21)">
                        <path id="Path_388" data-name="Path 388" d="M91.64,151.888l0,0-18.921-18.83a3.621,3.621,0,0,0-5.109,5.133l17.762,16.484L67.606,170.714a3.621,3.621,0,0,0,5.109,5.133l18.921-18.83,0,0A3.624,3.624,0,0,0,91.64,151.888Z" transform="translate(0 -132)" fill="#3F2E7F"/>
                        </g>
                    </g>
                </svg>
            </div>
        </div>
    )
};

export default WebSlider;